import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { window } from 'browser-monads';

const Footer = () => {
  const language = window.location.pathname.substring(0, 3);
  const intl = useIntl();
  const apropos = intl.formatMessage({ id: 'apropos' });
  const aproposRead = intl.formatMessage({ id: 'aproposRead' });
  const otherLinks = intl.formatMessage({ id: 'otherLinks' });
  const legal = intl.formatMessage({ id: 'legal' });
  const policy = intl.formatMessage({ id: 'policy' });
  const social = intl.formatMessage({ id: 'social' });

  return (
    <footer className='container mx-auto py-16 px-3 mb-8 text-gray-800 text-center'>
      <div className='flex -mx-3'>
        <div className='flex-1 px-3'>
          <h2 className='text-lg font-semibold'>{apropos}</h2>
          <ul className='mt-4 leading-loose'>
            <li>
              <Link to={language + '/apropos'}>{aproposRead}</Link>
            </li>
          </ul>
        </div>
        <div className='flex-1 px-3'>
          <h2 className='text-lg font-semibold'>{otherLinks}</h2>
          <ul className='mt-4 leading-loose'>
            <li>
              <Link to={language + '/conditions'}>{legal}</Link>
            </li>
            <li>
              <Link to={language + '/privacy'}>{policy}</Link>
            </li>
          </ul>
        </div>
        <div className='flex-1 px-3'>
          <h2 className='text-lg font-semibold'>{social}</h2>
          <ul className='mt-4 leading-loose'>
            <li>
              <a href='https://www.instagram.com/artngreen83440/' rel='noopener noreferrer' target='_blank'>Instagram</a>
            </li>
            <li>
              <a href='#'>Facebook</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
